import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link, StaticQuery } from "gatsby"

const activeExhibitionsQuery = graphql`
  query activeExhibitionsQuery {
    allContentfulExhibition(filter: { status: { eq: true } }) {
      nodes {
        venue
        name
        startDate
        endDate
        thumbnail {
          id
          title
          file {
            url
          }
        }
        shortDescription {
          shortDescription
        }
        description {
          json
        }
      }
    }
    allContentfulProject(filter: { status: { eq: true } }, limit: 3) {
      nodes {
        name
        thumbnail {
          id
          title
          file {
            url
          }
        }
        shortDescription {
          shortDescription
        }
        description {
          json
        }
      }
    }
  }
`

const months = [
  "januari",
  "februari",
  "mars",
  "april",
  "maj",
  "juni",
  "juli",
  "augusti",
  "september",
  "oktober",
  "november",
  "december",
]

class IndexPage extends React.Component {
  encodeRoute(s) {
    return s
      .toLowerCase()
      .replace(/[, ]+/g, " ")
      .trim()
      .replace(/(^\s+|[^a-zA-Z0-9 ]+|\s+$)/g, "")
      .replace(/ /g, "-")
  }

  render() {
    return (
      <StaticQuery
        query={activeExhibitionsQuery}
        render={data => {
          const {
            allContentfulExhibition: { nodes: exhibitions },
          } = data
          const {
            allContentfulProject: { nodes: projects },
          } = data

          return (
            <Layout>
              <SEO
                title="Aktuellt"
                keywords={[
                  `Utställningar`,
                  `Projekt`,
                  `Start`,
                  `Målningar`,
                  `Konst`,
                ]}
              />
              {exhibitions.map((node, index) => {
                const {
                  name,
                  venue,
                  thumbnail,
                  shortDescription: { shortDescription: description },
                } = node
                const startDate = new Date(node.startDate)
                const endDate = new Date(node.endDate)

                return (
                  <div
                    key={index}
                    className={"row"}
                    style={{ marginBottom: "25px" }}
                  >
                    <div className={"six columns"}>
                      <img
                        style={{ width: "100%" }}
                        src={thumbnail.file.url}
                        alt={thumbnail.title}
                      />
                    </div>
                    <div className={"six columns"}>
                      <h2
                        style={{
                          fontWeight: "900",
                          textTransform: "uppercase",
                        }}
                      >
                        {venue}
                      </h2>
                      <h3 style={{ fontSize: "22px", fontStyle: "italic" }}>
                        "{node.name}"
                      </h3>
                      <h3 style={{ fontSize: "18px", color: "#666666" }}>
                        {startDate.getDate()} {months[startDate.getMonth()]},{" "}
                        {startDate.getFullYear()} - {endDate.getDate()}{" "}
                        {months[endDate.getMonth()]}, {endDate.getFullYear()}
                      </h3>
                      <p>{description}</p>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"/exhibition/" + this.encodeRoute(name)}
                      >
                        Läs mer om utställningen
                      </Link>
                    </div>
                  </div>
                )
              })}
              <hr />
              <div className={"row"} style={{ marginTop: "-10px" }}>
                <h5 style={{ fontWeight: "900" }}>PROJEKT</h5>
              </div>
              <div className={"row"} style={{ marginTop: "5px" }}>
                {projects.map((node, index) => {
                  const columns =
                    projects.length === 3 ? "four columns" : "six columns"

                  const {
                    id,
                    name,
                    thumbnail,
                    shortDescription: { shortDescription: description },
                  } = node

                  return (
                    <React.Fragment key={id}>
                      <div key={index} className={columns}>
                        <img
                          style={{ width: "100%" }}
                          src={thumbnail.file.url}
                          alt={thumbnail.title}
                        />
                        {projects.length !== 1 ? (
                          <>
                            <h5
                              style={{
                                fontWeight: "900",
                                textTransform: "uppercase",
                                marginBottom: "0px",
                              }}
                            >
                              {name}
                            </h5>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={"/project/" + this.encodeRoute(name)}
                            >
                              Läs mer
                            </Link>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      {projects.length === 1 ? (
                        <div key={index + "."} className={"six columns"}>
                          <h2
                            style={{
                              fontWeight: "900",
                              textTransform: "uppercase",
                            }}
                          >
                            {name}
                          </h2>
                          <p>{description}</p>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"/exhibition/" + this.encodeRoute(name)}
                          >
                            Läs mer
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </Layout>
          )
        }}
      />
    )
  }
}

export default IndexPage
